// serializers
import {
    MainStyleSerializer,
    PageDetailSiteSerializer,
} from 'serializers/site'

// constants
export const REDUX_FORM_AUTO_SAVE_ADD_SITE = 'REDUX_FORM_AUTO_SAVE_ADD_SITE'
export const REDUX_FORM_AUTO_SAVE_REMOVE_SITE = 'REDUX_FORM_AUTO_SAVE_REMOVE_SITE'
export const REDUX_FORM_MODAL_FORM_SHOW_SITE = 'REDUX_FORM_MODAL_FORM_SHOW_SITE'
export const REDUX_FORM_SET_AVAILABILITY_SITE = 'REDUX_FORM_SET_AVAILABILITY_SITE'
export const REDUX_FORM_SET_COLLAPSE_STYLES_SITE = 'REDUX_FORM_SET_COLLAPSE_STYLES_SITE'
export const REDUX_FORM_SET_DATA_SITE = 'REDUX_FORM_SET_DATA_SITE'
export const REDUX_FORM_SET_DYNAMIC_TEXT_OPTIONS_SITE = 'REDUX_FORM_SET_DYNAMIC_TEXT_OPTIONS_SITE'
export const REDUX_FORM_SET_HOVERED_BLOCK_ID_SITE = 'REDUX_FORM_SET_HOVERED_BLOCK_ID_SITE'
export const REDUX_FORM_SET_IS_EDITING_SITE = 'REDUX_FORM_SET_IS_EDITING_SITE'
export const REDUX_FORM_SET_IS_SUCCESS_TOAST_OPEN_SITE = 'REDUX_FORM_SET_IS_SUCCESS_TOAST_OPEN_SITE'
export const REDUX_FORM_SET_NAVIGATION_MODE_SITE = 'REDUX_FORM_SET_NAVIGATION_MODE_SITE'
export const REDUX_FORM_SET_PAGE_LIST_SITE = 'REDUX_FORM_SET_PAGE_LIST_SITE'
export const REDUX_FORM_SET_PARENT_ACTIVE_SITE = 'REDUX_FORM_SET_PARENT_ACTIVE_SITE'
export const REDUX_FORM_SET_PERMISSIONS_SITE = 'REDUX_FORM_SET_PERMISSIONS_SITE'
export const REDUX_FORM_SET_REFRESH_SITE = 'REDUX_FORM_SET_REFRESH_SITE'
export const REDUX_FORM_SET_SECTION_COLLAPSE_SITE = 'REDUX_FORM_SET_SECTION_COLLAPSE_SITE'
export const REDUX_FORM_SET_SECTION_ID_SITE = 'REDUX_FORM_SET_SECTION_ID_SITE'
export const REDUX_FORM_SHOW_EDIT_ALERT_SITE = 'REDUX_FORM_SHOW_EDIT_ALERT_SITE'

// actions
export function reduxFormAutoSaveAddSite(
    contentId: number,
    styles: MainStyleSerializer,
    styles_original: MainStyleSerializer,
    udpdateUrl: string,
) {
    return {
        type: REDUX_FORM_AUTO_SAVE_ADD_SITE,
        payload: {
            contentId: contentId,
            styles: styles,
            styles_original: styles_original,
            udpdateUrl: udpdateUrl,
        },
    }
}

export function reduxFormAutoSaveRemoveSite(contentId: number, cancel?: boolean) {
    return {
        type: REDUX_FORM_AUTO_SAVE_REMOVE_SITE,
        payload: {
            cancel: cancel,
            contentId: contentId,
        },
    }
}

export function reduxFormModalFormShowSite(isOpen: boolean, contentType?: string, objectId?: number) {
    return {
        type: REDUX_FORM_MODAL_FORM_SHOW_SITE,
        payload: {
            contentType,
            isOpen,
            objectId,
        },
    }
}

export function reduxFormSetAvailabitySite(data: any) {
    return {
        type: REDUX_FORM_SET_AVAILABILITY_SITE,
        payload: data,
    }
}

export function reduxFormSetCollapseStylesSite(boolean: boolean) {
    return {
        type: REDUX_FORM_SET_COLLAPSE_STYLES_SITE,
        payload: boolean,
    }
}

export function reduxFormSetDataSite(branch: string, content: any) {
    return {
        type: REDUX_FORM_SET_DATA_SITE,
        payload: {
            branch: branch,
            content: content,
        },
    }
}

export function reduxFormSetDynamicTextOptionsSite(results: any) {
    return {
        type: REDUX_FORM_SET_DYNAMIC_TEXT_OPTIONS_SITE,
        payload: results,
    }
}

export function reduxFormSetHoveredBlockIdSite(blockId: number | undefined) {
    return {
        type: REDUX_FORM_SET_HOVERED_BLOCK_ID_SITE,
        payload: blockId,
    }
}

export function reduxFormSetIsSuccessToastOpenSite(value: boolean, message?: string) {
    return {
        type: REDUX_FORM_SET_IS_SUCCESS_TOAST_OPEN_SITE,
        payload: {
            message: message,
            value: value,
        },
    }
}

export function reduxFormSetEditingSite(boolean: boolean, styles_original?: boolean) {
    return {
        type: REDUX_FORM_SET_IS_EDITING_SITE,
        payload: {
            boolean: boolean,
            styles_original: styles_original,
        },
    }
}

export function reduxFormSetNavigationModeSite(navMode: 'edit' | 'navMixed' | 'navPage' | 'preview' | undefined) {
    return {
        type: REDUX_FORM_SET_NAVIGATION_MODE_SITE,
        payload: navMode,
    }
}

export function reduxFormSetPageListSite(pages: PageDetailSiteSerializer[]) {
    return {
        type: REDUX_FORM_SET_PAGE_LIST_SITE,
        payload: pages,
    }
}

export function reduxFormSetParentActiveSite(parentContent: number | undefined) {
    return {
        type: REDUX_FORM_SET_PARENT_ACTIVE_SITE,
        payload: parentContent,
    }
}

export function reduxFormSetPermissionsSite(permissions: any) {
    return {
        type: REDUX_FORM_SET_PERMISSIONS_SITE,
        payload: permissions,
    }
}

export function reduxFormSetSectionCollapseSite(boolean: boolean) {
    return {
        type: REDUX_FORM_SET_SECTION_COLLAPSE_SITE,
        payload: boolean,
    }
}

export function reduxFormSetSectionIdSite(sectionId: number | undefined) {
    return {
        type: REDUX_FORM_SET_SECTION_ID_SITE,
        payload: sectionId,
    }
}

export function reduxFormSetRefreshSite(
    refreshType:
        'PrivateDetailPageSite',
    refreshId?: number,
    noEmpty?: boolean,
) {
    return {
        type: REDUX_FORM_SET_REFRESH_SITE,
        payload: {
            noEmpty: noEmpty,
            refreshId: refreshId,
            refreshType: refreshType,
            refreshValue: Math.random(),
        },
    }
}

export function reduxFormShowEditAlertSite(
    show: boolean,
    editUrl?: string,
    editFunction?: () => void,
) {
    return {
        type: REDUX_FORM_SHOW_EDIT_ALERT_SITE,
        payload: {
            editFunction: editFunction,
            editUrl: editUrl,
            showEditAlert: show,
        },
    }
}
